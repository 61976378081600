/* eslint-disable ts/ban-ts-comment */
import { isArray, isClient, isString } from '@qctsw/utils'
import { APP_ROUTER, getHostPagePath } from '@qctsw/common'
import { ISSUE_TYPE_CODE_ENUM } from '../constants'
import type { ComplaintDetailResponse } from '~/server/types'

export function checkRouteIsActive(reg: RegExp | string): boolean {
  const route = useRoute()
  const { fullPath } = route
  return !!fullPath.match(reg)
}

/**
 * Tips: setup中使用
 * 返回上一级页面
 */
export function goBackPage() {
  if (!isClient)
    return
  const pageLen = history.length
  const router = useRouter()

  if (router) {
    if (pageLen > 2)
      router.back()
    else
      router.replace(APP_ROUTER.home())
  }
  else {
    if (pageLen > 2)
      history.back()
    else
      window.location.href = getHostPagePath('app', APP_ROUTER.home())
  }
}

/**
 * 获取路由参数
 * @param key 路由参数名
 * @param type 路由参数类型string | ['string']
 * @returns ComputedRef<string | string[]>
 */
export function useGetRouteParam<T>(key: string, defaultValue: T): ComputedRef<T> {
  const route = useRoute()
  const param = computed(() => {
    if (!isArray(defaultValue)) {
      const param = route.params[key]
      return (isString(param) ? param : param?.[0]) || defaultValue
    }
    else {
      const param = route.params[key]
      return ((param && isArray(param))
        ? param
        : param ? [param] : []
      ).length || defaultValue
    }
  })
  return param as ComputedRef<T>
}

export function useGetRouteQuery<T>(key: string, defaultValue: T): ComputedRef<T> {
  const route = useRoute()
  const param = computed(() => {
    if (!isArray(defaultValue)) {
      const param = route.query[key]
      return (isString(param) ? param : param?.[0]) || defaultValue
    }
    else {
      const param = route.query[key]
      if (param && isArray(param))
        return param
      if (isString(param) && param.includes(','))
        return param.split(',')
      return param ? [param] : defaultValue
    }
  })
  return param as ComputedRef<T>
}

export function isOnlyRouteQueryKeyChange(_key: string, toQuery: Record<string, any>, fromQuery: Record<string, any>): boolean {
  let isOnlyPageQueryChange = true
  for (const key in toQuery) {
    if (key !== _key && toQuery[key] !== fromQuery[key]) {
      isOnlyPageQueryChange = false
      break
    }
  }

  return isOnlyPageQueryChange
}

export function goAppPage(path: string) {
  if (!isClient)
    return
  const router = useRouter()
  if (router)
    router.push(path)
  else
    window.location.href = getHostPagePath('app', path)
}

/**
 * 生成投诉问题的路由参数
 * @param item
 * @returns
 */
export function genIssueRouteQuery(item: ComplaintDetailResponse['issueLs'][number]) {
  const keys = {
    [ISSUE_TYPE_CODE_ENUM.quality]: 'quality',
    [ISSUE_TYPE_CODE_ENUM.sale]: 'sale',
    [ISSUE_TYPE_CODE_ENUM.afterSale]: 'afterSale',
  } as const

  const partKeys = {
    47: 'part_1', // 轮胎 47
    51: 'part_2', // 机油 51
    52: 'part_3', // 变速箱油 52
    53: 'part_4', // 传动油 53
  }

  const query = {}
  if (!Object.keys(keys).includes(`${item.issueTypeCode}`))
    return {}

  // @ts-expect-error
  query[keys[item.issueTypeCode]] = item.issueId

  if (Object.keys(partKeys).includes(`${item.childIssueId}`))
  // @ts-expect-error
    query[partKeys[item.childIssueId]] = item.carPartsId

  return query
}
